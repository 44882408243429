import React from 'react';

import {
  UserOutlined,
  DashboardOutlined,
  TeamOutlined,
  DownOutlined,
  NotificationOutlined,
  FileDoneOutlined,
  SettingOutlined,
  LoginOutlined,
  HomeOutlined,
  QuestionOutlined,
  TrademarkCircleOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Dropdown, Space, Tabs } from 'antd';
import { useState, useEffect } from 'react';
import userService from '../services/user.service';
import noticeService from '../services/notice.service';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../store/UserContext';
import AnnouncementNoticeModel from './AnnouncementNoticeModel';
import { useLocation } from 'react-router-dom';

const { Header, Sider, Content } = Layout;
const { TabPane } = Tabs;

const LayoutUi = ({ children }) => {
  // 使用UserContext來獲取和設置用戶資訊
  const { user, setUser } = useUserContext();
  // 狀態變量，用於控制通知的顯示
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  // 系統資訊
  const [systemInfo, setSystemInfo] = useState();
  // 用於導航的hook
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);

  // 導航項目的狀態
  const [navItems, setNavItems] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const location = useLocation();
  const currentTabKey =
    navItems.find((item) => item.route === location.pathname)?.key || '1';

  const getNotice = () => {
    // 獲取公告
    noticeService.getNotice().then((res) => {
      setSystemInfo(res);
      // notice只會展示一次，展示之後，就回把notice裡面的id和當前的域名保存在localstorage裡面，下一次進來，再比對，如果存在就展示，不存在就不顯示
      let storeNotices = localStorage.getItem('storeNotices');
      storeNotices = storeNotices ? JSON.parse(storeNotices) : [];
      const key = res?.notice?.id;
      if (!storeNotices.includes(key) && res?.notice?.show === 1) {
        setIsOpenNotice(true);
        storeNotices.push(key);
        localStorage.setItem('storeNotices', JSON.stringify(storeNotices));
      }
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call the handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    userService.getUserInfo().then((res) => {
      setUser(res);
      if (res?.admin) {
        // "系統管理員Admin" 只管理經銷商，訂單（後半流程），對經銷商的公告
        setNavItems([
          {
            key: '0',
            route: '/order',
            icon: <FileDoneOutlined />,
            label: '訂單',
          },
          {
            key: '1',
            route: '/dealer',
            icon: <UserOutlined />,
            label: '經銷商管理',
          },
          {
            key: '2',
            route: '/notice',
            icon: <NotificationOutlined />,
            label: '公告管理',
          }
        ]);
      } else if (res?.staff) {
        // 只有"非管理員"才能獲取公告
        // "非管理員"還分主帳號跟子帳號
        // 主帳號: res?.dealer?.dealer_account_type === 1
        // 子帳號: res?.dealer?.dealer_account_type === 2

        getNotice();
        if (res?.dealer?.dealer_account_type === 1) {
          // "非管理員主帳號"可見：Dashboard, 員工管理，用戶管理，訂單（前半流程），詢價管理，公告管理

          setNavItems([
            {
              key: '0',
              route: '/order',
              icon: <FileDoneOutlined />,
              label: '訂單',
            },
            {
              key: '1',
              route: '/inquiry',
              icon: <QuestionOutlined />,
              label: '詢價管理',
            },
            {
              key: '2',
              route: `/dealer/staff/${res?.dealer?.id}`,
              icon: <TeamOutlined />,
              label: '員工管理',
            },
            {
              key: '3',
              route: '/user',
              icon: <UserOutlined />,
              label: '用户管理',
            }, 
            {
              key: '4',
              route: '/notice',
              icon: <NotificationOutlined />,
              label: '公告管理',
            },
            {
              key: '5',
              route: '/dashboard',
              icon: <DashboardOutlined />,
              label: 'Dashboard',
            },
          ]);
        } else if (res?.dealer?.dealer_account_type === 0) {
          setNavItems([
            {
              key: '0',
              route: '/order',
              icon: <FileDoneOutlined />,
              label: '訂單',
            },
            {
              key: '1',
              route: '/inquiry',
              icon: <QuestionOutlined />,
              label: '詢價管理',
            },
            {
              key: '2',
              route: '/dashboard',
              icon: <DashboardOutlined />,
              label: 'Dashboard',
            },
            {
              key: '3',
              route: '/user',
              icon: <UserOutlined />,
              label: '用户管理',
            },

            {
              key: '4',
              route: '/notice',
              icon: <NotificationOutlined />,
              label: '公告管理',
            }
          ]);
        } else if (res?.dealer?.dealer_account_type === 2) { // 審稿帳號
          setNavItems([
            {
              key: '0',
              route: '/order',
              icon: <FileDoneOutlined />,
              label: '訂單',
            },
            {
              key: '1',
              route: '/inquiry',
              icon: <QuestionOutlined />,
              label: '詢價管理',
            },
            {
              key: '2',
              route: '/notice',
              icon: <NotificationOutlined />,
              label: '公告管理',
            }
          ]);
        }
      }
    });
  }, []);

  const handleMenu = (keyOrEvent) => {
    const key = keyOrEvent.key ? keyOrEvent.key : keyOrEvent;
    console.log('click key', key);
    const route = navItems.find((item) => item.key === key)?.route;
    console.log('click route', route);
    if (key.length == 1) {
      const route = navItems.find((item) => item.key === key)?.route;
      navigate(route);
    } else {
      navigate(key);
    }
  };

  const handleOut = () => {
    localStorage.removeItem('accessToken');
    navigate('/signin');
  };

  const handleGoInfo = () => {
    navigate('/user/info');
  };

  const menu = () => {
    const items = [
      {
        key: '2',
        label: (
          <div
            onClick={() => navigate('/setting')}
            style={{ fontSize: '14px', padding: '4px 10px' }}
          >
            <SettingOutlined style={{ marginRight: '10px' }} />
            修改密碼
          </div>
        ),
      },
      {
        key: '3',
        label: (
          <div
            onClick={handleOut}
            style={{ fontSize: '14px', padding: '4px 10px' }}
          >
            <LoginOutlined style={{ marginRight: '10px' }} />
            退出登錄
          </div>
        ),
      },
    ];
    if (!user?.admin) {
      items.unshift({
        key: '1',
        label: (
          <div
            onClick={() => navigate('/dealer-info')}
            style={{ fontSize: '14px', padding: '4px 10px' }}
          >
            <HomeOutlined style={{ marginRight: '10px' }} />
            經銷商資料
          </div>
        ),
      });
    }
    return <Menu items={items} />;
  };

  const renderMobileMenu = () => (
    <Tabs defaultActiveKey={currentTabKey} onChange={handleMenu}>
      {navItems.map((item) => (
        <TabPane
          tab={
            <span>
              {React.cloneElement(item.icon)}
              {item.label}
            </span>
          }
          key={item.key}
        />
      ))}
    </Tabs>
  );

  const [selectedKey, setSelectedKey] = useState('');

  useEffect(() => {
    // 这里应该包含一个函数，它能够根据 location.pathname 解析出正确的 key
    // 下面是一个简单的示例，您需要根据实际的路由结构调整它
    console.log('navItems:', navItems);
    const matchNavItem = navItems.find((item) => {
      return (
        location.pathname === item.route ||
        location.pathname.includes(item.route)
      );
    });
    console.log('matchNavItem', matchNavItem);
    if (matchNavItem) {
      setSelectedKey(matchNavItem.route);
    }
  }, [location, navItems]);

  return (
    <Layout style={{ height: '100vh' }}>
      {!isMobile && (
        <Sider trigger={null} collapsible>
          <div className="logo" style={{ height: '60px' }} />
          <Menu
            theme="dark"
            mode="inline"
            onClick={handleMenu}
            selectedKeys={[selectedKey]}
            items={navItems.map((item) => ({
              key: item.route,
              icon: React.cloneElement(item.icon),
              label: item.label,
            }))}
          />
        </Sider>
      )}

      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: '0 30px',
            color: 'white',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span
            style={{ fontSize: '16px', fontWeight: '600', color: '#b5b5b5' }}
          >
            {!user?.admin ? `${user?.dealer.name} 經銷商` : 'Uberprint'}管理系統
          </span>
          <div>
            <span style={{ cursor: 'pointer' }} onClick={handleGoInfo}></span>
            <Dropdown overlay={menu}>
              <a
                onClick={(e) => e.preventDefault()}
                style={{ fontSize: '14px', fontWeight: 600 }}
              >
                <Space>
                {!user?.admin ? user?.username : "合印管理者"}
              
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
            {/* <PoweroffOutlined style={{ cursor: "pointer", marginLeft: "10px" }} onClick={handleOut} /> */}
          </div>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            padding: 24,
            minHeight: 280,
            background: '#ffffff',
            overflow: 'auto',
          }}
        >
          {isMobile && renderMobileMenu()}
          {children}
        </Content>
      </Layout>
      <AnnouncementNoticeModel
        isOpenNotice={isOpenNotice}
        setIsOpenNotice={setIsOpenNotice}
        noticeInfo={systemInfo?.notice}
      />
    </Layout>
  );
};

export default LayoutUi;
