import { useState, createContext, useContext, useMemo } from "react";

// @function  UserContext
const UserContext = createContext();

function useUserContext() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error(`useUserContext must be used within a CountProvider`);
  }
  return context;
}

// @function  UserProvider
// Create function to provide UserContext
function UserProvider({ children }) {
  const [user, setUser] = useState();

  const value = useMemo(() => ({ user, setUser }), [user]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
export { UserProvider, useUserContext };
