import { message } from "antd";
import axios from "axios";
axios.defaults.baseURL = "https://apimaindev.simplework.site";

axios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      config.headers["Authorization"] = "Bearer " + accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response) {
      return response.data;
    }
  },
  (error) => {
    if (error.response?.status === 401) {
      window.location.replace("/signin");
    } else {
      message.error(error.response?.data?.msg);
    }
    return Promise.reject(error.response?.data);
  }
);
