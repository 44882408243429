import RouterHandler from "./RouterHandler";
import { ConfigProvider } from 'antd';

// Store
import { UserProvider } from "./store/UserContext";
import "./utils/Axios";

import "./App.css";
import theme from './theme';

function App() {
  console.warn('Commit Hash', process.env.REACT_APP_GIT_COMMIT);
  return (
    <div className="App">
      <ConfigProvider theme={theme}>
        <UserProvider>
          <RouterHandler />
        </UserProvider>
      </ConfigProvider>
    </div>
  );
}

export default App;
