const theme = {
  token: {
    colorPrimary: '#3487f2',
    colorInfo: '#3487f2',
    colorSuccess: '#62e835',
    colorWarning: '#eb9342',
    colorError: '#d43b2b',
    colorTextBase: '#1b1b1c',
    colorBgBase: '#faf9fa',
    fontSize: 16,
    sizeStep: 4,
    borderRadius: 6,
    borderRadiusLG: 6,
    wireframe: false,
  },
  components: {
    Layout: {
      footerBg: '#E3E4E8',
    },
    Button: {
      defaultColor: '#1b1b1c',
      defaultGhostColor: '#1b1b1c',
      linkHoverBg: 'rgba(27, 27, 28, 0.06)',
      paddingInline: 16,
      borderRadius: 6,
    },
    Card: {
      colorTextDescription: '#1b1b1c',
      actionsLiMargin: '6px',
    },
    Form: {
      itemMarginBottom: 8,
    },
    Input: {
      borderRadius: 6,
    },
    Select: {
      selectorBg: '#faf9fa',
      optionFontSize: '1rem',
      borderRadius: 6,
    },
  },
};

export default theme;
