import React from "react";

/**
 * @memberof Routes
 * @constant routes
 */
const routes = [
  {
    path: '/signin',
    component: React.lazy(() => import('./pages/user/Signin')),
  },
  {
    path: '/signup',
    component: React.lazy(() => import('./pages/user/Signup')),
  },
  {
    path: '/forget',
    component: React.lazy(() => import('./pages/user/forget')),
  },
  {
    path: '/password',
    component: React.lazy(() => import('./pages/user/Password')),
  },
  {
    path: '/user',
    component: React.lazy(() => import('./pages/user/List')),
    isAuth: true,
  },
  {
    path: '/notice',
    component: React.lazy(() => import('./pages/notice')),
    isAuth: true,
  },
  {
    path: '/user/balanceHistory/:id',
    component: React.lazy(() => import('./pages/user/BalanceHistory')),
    isAuth: true,
  },
  // {
  //   path: '/user/info',
  //   component: React.lazy(() => import('./pages/user/Info')),
  //   isAuth: true,
  // },
  {
    path: '/store/list',
    component: React.lazy(() => import('./pages/store/List')),
    isAuth: true,
  },
  {
    path: '/order',
    component: React.lazy(() => import('./pages/order/List')),
    isAuth: true,
  },
  {
    path: '/inquiry',
    component: React.lazy(() => import('./pages/inquiry/InquiryList')),
    isAuth: true,
  },

  {
    path: '/setting',
    component: React.lazy(() => import('./pages/user/Setting')),
    isAuth: true,
  },

  // 經銷商路由
  {
    path: '/dealer',
    component: React.lazy(() => import('./pages/dealer/index')),
    isAuth: true,
  },
  {
    path: '/dealer-info',
    component: React.lazy(() => import('./pages/dealer/Info')),
    isAuth: true,
  },
  {
    path: '/dealer/staff/:id',
    component: React.lazy(() => import('./pages/dealer/staff')),
    isAuth: true,
  },
  {
    path: '/dealer/balance-history/:id',
    component: React.lazy(() => import('./pages/dealer/BalanceHistory')),
    isAuth: true,
  },
  {
    path: '/dashboard',
    component: React.lazy(() => import('./pages/dashboard/index')),
    isAuth: true,
  },
  {
    path: '*',
    component: React.lazy(() => import('./pages/user/List')),
    isAuth: true,
  },
];

export default routes;
