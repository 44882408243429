import { Modal } from "antd";

const DetailModal = ({ isOpenNotice, setIsOpenNotice, noticeInfo }) => {
  const lableStyle = { width: "100px", display: "inline-block", textAlign: "right" };

  return (
    <Modal title="公告信息" open={isOpenNotice} onCancel={() => setIsOpenNotice(false)} footer={false} width={800}>
      <div style={{ lineHeight: "34px", fontSize: "16px" }}>
        <div style={{ margin: "10px 0px", fontWeight: 600 }}>
          <span style={lableStyle}>标题：</span>
          {noticeInfo?.title}
        </div>
        {noticeInfo?.logo_url && (
          <div>
            <span style={lableStyle}>图片：</span>
            <img height={46} src={noticeInfo?.logo_url} />
          </div>
        )}
        <div>
          <span style={lableStyle}>詳情：</span>
          <span style={{ display: "inline-block", width: "calc(100% - 140px)", verticalAlign: "top" }}>
            {noticeInfo?.content}
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default DetailModal;
