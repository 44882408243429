import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RouterConfig from "./RouterConfig";
import React from "react";
import Layout from "./ui/MainLayout";

export default function RouterHandler() {
  const renderRoute = ({ path, component: Component, isAuth }) => {
    return (
      <Route
        key={path}
        path={path}
        element={
          <React.Suspense>
            {isAuth ? (
              <Layout>
                <Component />
              </Layout>
            ) : (
              <Component />
            )}
          </React.Suspense>
        }
      />
    );
  };
  return (
    <Router>
      <Routes>{RouterConfig.map(renderRoute)}</Routes>
    </Router>
  );
}
